import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { transformRelationsToSelectValue } from 'components/common/controls/custom-select/relations-select/utils';

import { showNoticeMessage } from 'services/notice';

import {
  // createTemplateRelationLocal,
  // deleteTemplateRelationLocal,
  editTemplateFieldsLocal,
  fetchTemplateAgreementStepsLocal,
  fetchTemplateRelationsLocal
} from '../../../../store/tasks';
import BaseForm from '../../view/drawers/editor/base-form';
import transformTask from '../../../../utils/transform-task';
import {
  clearAccordingType,
  getTaskValue
} from '../../view/drawers/creator/utils';
// import { updateRelations } from '../../view/drawers/creator/relations-form';
import getFileIds from '../../../../hooks/common/use-file-upload/get-file-ids';
import Drawer from '../../../common/drawer';
import useTemplateRelations from '../use-template-relations';

const getEditTemplatePermissions = ({ isPeriodical }) => ({
  changeDateStart: !!isPeriodical,
  changeDateEnd: !!isPeriodical,
  updateTask: true,
  updateTaskEstimate: false, // пока не реализовано на бэке
  addTaskMember: true,
  deleteTaskMember: true,
  changeTaskResponsible: true,
  changeTaskController: true,
  createRelation: true,
  deleteRelation: true
});

const TemplateEditorDrawer = ({
  template: _template,
  visible,
  onClose,
  callback,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const { updateTemplateRelations } = useTemplateRelations();

  const [template, setTemplate] = useState(_template);
  const [relations, setRelations] = useState();
  const [steps, setSteps] = useState([]);

  const { t } = useTranslation(['AddTask', 'Toast']);

  useEffect(() => {
    if (_template) {
      setTemplate(_template);
    }
  }, [_template]);

  const transformedTemplate =
    template &&
    transformTask({
      ...template,
      permissions: getEditTemplatePermissions(template),
      members: template.members,
      relations,
      agreementSteps: steps
    });

  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingDeps, setIsLoadingDeps] = useState(true);

  const dispatchNewFields = async values => {
    const resultTask = getTaskValue(clearAccordingType(values));

    const value = {
      id: template.id,
      data: {
        ...resultTask,
        responsible: (values.responsible || []).map(r => r.value),
        controller: (values.controller || {}).value || null,
        description: values.description || undefined,
        dateEnd: values.dateEnd || null,
        dateStart: values.dateStart || null,
        fileList: getFileIds(values.fileList),
        members: (values.members || []).map(member => member.value)
      }
    };

    return dispatch(editTemplateFieldsLocal({ value }));
  };

  const sendValues = async ({ values }) => {
    try {
      setIsLoadingSend(true);

      const updatedTemplate = await dispatchNewFields(values);

      await updateTemplateRelations(
        relations || [],
        values.relations || [],
        template.id
      );

      await callback();

      showNoticeMessage({
        customContent: t('TemplateEdited', { ns: 'Toast' })
      });

      onClose(updatedTemplate);
    } finally {
      setIsLoadingSend(false);
    }
  };

  useEffect(() => {
    const fetchDependencies = async () => {
      setIsLoadingDeps(true);

      const templateRelations = await dispatch(
        fetchTemplateRelationsLocal({ id: template.id })
      );

      const { agreementSteps } = await dispatch(
        fetchTemplateAgreementStepsLocal({ id: template.id })
      );

      const transformSteps = (agreementSteps || []).map((step, index) => ({
        isEdit: true,
        employees: step[index].map(e => ({ value: e.id, label: e }))
      }));

      setRelations(transformRelationsToSelectValue(templateRelations));
      setSteps(transformSteps);

      setIsLoadingDeps(false);
    };

    if (visible && !!(template || {}).id) {
      fetchDependencies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, (template || {}).id]);

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      data-qa="qa-v57huwy3lgghdm1"
      visible={visible}
      title={<Drawer.Title>{t('EditTaskTemplateHeading')}</Drawer.Title>}
      onClose={() => onClose()}
      {...drawerProps}
    >
      <BaseForm
        onSubmit={sendValues}
        values={transformedTemplate}
        isLoadingAll={isLoadingDeps}
        isLoading={isLoadingSend}
        isTemplate
        data-qa="qa-fxb2wzambq74m9d"
      />
    </Drawer>
  );
};

TemplateEditorDrawer.propTypes = {
  template: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func
};

TemplateEditorDrawer.defaultProps = {
  template: {},
  callback: () => {}
};

export default TemplateEditorDrawer;
